import React from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

/* Import Global Asset(s) */
import CrossSvgSrc from "~assets/images/close.svg"

/* Import Local Style(s) */
import "./podcast-header.scss"

const PodcastHeader = ({ location, podcast: { episode, title } }) => {
  const prevUrlPath = location.state?.prevUrlPath

  return (
    <header className="podcast-header">
      <p className="episode">{episode}</p>
      <h1>{title}</h1>
      <Link to={prevUrlPath ? prevUrlPath : "/podcast"}>
        <img src={CrossSvgSrc} alt="Cross" />
      </Link>
    </header>
  )
}

export default PodcastHeader
