import React, { useContext } from "react"
import classnames from "classnames"

/* Import Global Context(s) */
import AudioContext from "~context/audio"

/* Import Local Components */
import Duration from "./components/duration"

/* Import Local Style(s) */
import "./audio-controller.scss"

const AudioController = () => {
  const {
    setIsPlaying,
    isLoading,
    isPlaying,
    duration,
    played,
    // progress,
    playedSeconds,
    handleSeekMouseDown,
    handleSeekChange,
    handleSeekMouseUp,
  } = useContext(AudioContext)

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying)
  }

  return (
    <div className={classnames("audio-player", { "is-loading": isLoading })}>
      <div className="timeline">
        <input
          type="range"
          min={0}
          max={0.999999}
          step="any"
          value={isLoading ? 0 : played}
          onMouseDown={handleSeekMouseDown}
          onChange={handleSeekChange}
          onMouseUp={handleSeekMouseUp}
        />
      </div>
      <div className="controls-and-information">
        {isPlaying ? (
          <button className="controls" onClick={handlePlayPause}>
            Pause
          </button>
        ) : (
          <button className="controls" onClick={handlePlayPause}>
            Play
          </button>
        )}
        <div className="information">
          <Duration
            duration={isLoading ? 0 : duration}
            progress={isLoading ? 0 : playedSeconds}
          />
        </div>
      </div>
    </div>
  )
}

export default AudioController
