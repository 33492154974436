import React, { useContext, useEffect } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { parseISO } from "date-fns"
import { format } from "date-fns-tz"

/* Import Global Context(s) */
import AudioContext from "~context/audio"

/* Import Global Component(s) */
import BlockContent from "~components/blockContent/blockContent"
import AudioController from "~components/audioController/audioController"
import Page from "~components/page/page"
import PageArticle from "~components/page/components/pageArticle/pageArticle"

/* Import Local Components */
import PodcastHeader from "./components/podcastHeader/podcastHeader"

/* Import Local Style(s) */
import "./podcast.scss"

const Podcast = ({ location, data: { sanityPodcast: podcast } }) => {
  const {
    episode: episodeNumber,
    publicationDate,
    _rawDescription,
    color: {
      color: { hex },
    },
    url,
  } = podcast
  const {
    url: audioContextUrl,
    setReactPlayerUrl,
    isPlaying,
    setShowController,
    setExpandLinkUrl,
    setBackgroundColor,
    setEpisodeNumber,
  } = useContext(AudioContext)

  useEffect(() => {
    setShowController(false)
    setBackgroundColor(hex)
    setExpandLinkUrl(location.pathname)
    setEpisodeNumber(episodeNumber)
  }, [
    setShowController,
    hex,
    setBackgroundColor,
    setExpandLinkUrl,
    setEpisodeNumber,
    episodeNumber,
    location,
  ])

  // When Loading Page, Hide Controller if Visible
  useEffect(() => {
    setShowController(false)
  }, [])

  // Set New URL
  useEffect(() => {
    if (audioContextUrl !== url) {
      setReactPlayerUrl(url)
    }
  }, [audioContextUrl, setReactPlayerUrl, url])

  return (
    <Page
      title="Podcast"
      location={location}
      className="template"
      style={{ backgroundColor: hex }}
    >
      <Helmet>
        <style>{`body { background-color: ${hex}; }`}</style>
      </Helmet>
      <PodcastHeader podcast={podcast} location={location} />
      <PageArticle title="Podcast" className="podcast">
        <p className="date mobile-only">
          {format(parseISO(publicationDate), "d MMMM yyyy")}
        </p>
        <div className="page__article--podcast__information">
          <p className="date desktop-only">
            {format(parseISO(publicationDate), "d MMMM yyyy")}
          </p>
          <div className="description">
            <BlockContent blocks={_rawDescription} />
          </div>
        </div>
        <AudioController />
      </PageArticle>
    </Page>
  )
}

export default Podcast

export const query = graphql`
  query ($id: String!) {
    sanityPodcast(id: { eq: $id }) {
      ...fieldsOnPodcastDocument
    }
  }
`
