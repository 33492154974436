import React from "react"

export default function Duration({ className, duration = 0, progress = 0 }) {
  return (
    <div>
      <p>
        <time dateTime={`P${Math.round(duration)}S`} className={className}>
          {format(progress)}
        </time>
        <span>/</span>
        <time dateTime={`P${Math.round(progress)}S`} className={className}>
          {format(duration)}
        </time>
      </p>
    </div>
  )
}

function format(seconds) {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `00:${mm}:${ss}`
}

function pad(string) {
  return ("0" + string).slice(-2)
}
